// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-affordable-housing-in-santa-ana-js": () => import("./../src/pages/issues/affordable-housing-in-santa-ana.js" /* webpackChunkName: "component---src-pages-issues-affordable-housing-in-santa-ana-js" */),
  "component---src-pages-issues-homelessness-in-santa-ana-js": () => import("./../src/pages/issues/homelessness-in-santa-ana.js" /* webpackChunkName: "component---src-pages-issues-homelessness-in-santa-ana-js" */),
  "component---src-pages-issues-investing-in-santa-ana-youth-js": () => import("./../src/pages/issues/investing-in-santa-ana-youth.js" /* webpackChunkName: "component---src-pages-issues-investing-in-santa-ana-youth-js" */),
  "component---src-pages-issues-public-health-js": () => import("./../src/pages/issues/public-health.js" /* webpackChunkName: "component---src-pages-issues-public-health-js" */),
  "component---src-pages-issues-safer-neighborhoods-in-santa-ana-js": () => import("./../src/pages/issues/safer-neighborhoods-in-santa-ana.js" /* webpackChunkName: "component---src-pages-issues-safer-neighborhoods-in-santa-ana-js" */),
  "component---src-pages-updates-js": () => import("./../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

